.monitor {
    .date-button {
        color: #6c757d;
        background-color: #fff;
        border-color: #dee2e6;
        border-radius: 10px;
        padding: 0.15rem 0.5rem;

        &:hover {
            color: #56a700;
            cursor: pointer;
        }
    }

    .expand-button {
        &:hover {
            color: #56a700;
            cursor: pointer;
        }
    }

    .monitor-wrapper {
        flex-wrap: nowrap;
        overflow: scroll;

        .monitor-column {
            flex: 1;
            background-color: white;
            padding: 15px;
            margin: 15px;
            height: 100%;
            border-radius: 10px;
            min-width: 250px;

            .bordered {
                border-radius: 5px;

                &.on-the-way {
                    background-color: #ffff00;
                }

                &.arrived {
                    background-color: #9ec2a3fd;
                }

                &.done {
                    background-color: #fdbafffd;
                }
            }
        }
    }

    .column-list {
        margin: 15px 0;
        max-height: 60vh;
        overflow: scroll;
    }

    .column-info {
        font-weight: 700;
        font-size: 18px;
        min-height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .column-item {
        display: flex;
        border: 1px solid #dee2e6;
        background-color: #f5f6f8;
        border-radius: 10px;
        padding: 15px;

        + .column-item {
            margin: 15px 0 0;
        }
    }

    .pallet {
        display: block;
    }

    .item-row {
        display: flex;
        justify-content: space-between;
    }

    .item-information {
        width: 100%;
    }

    .item-quantity {
        padding-left: 10px;
    }

    .item-date {
        color: #56a700;
    }

    .info-label {
        font-size: 17px;
        padding: 4px 0;

        .report-icon-wrapper {
            padding-left: 5px;

            .icon {
                font-size: 23px;
            }
        }
    }

    .column-summary {
        text-align: center;
        font-weight: 600;
        font-size: 18px;
    }

    .next-enter-active,
    .next-leave-active {
        overflow: hidden;
        transition: opacity 0.3s, transform 0.5s;
    }

    .next-enter {
        opacity: 0;
        overflow: hidden;
        transform: translateX(40%);
    }

    .next-leave-to {
        opacity: 0;
        overflow: hidden;
        transform: translateX(-40%);
    }

    .logo-image {
        max-height: 46px;
        margin: 0 2.5rem;
    }

    .get-more {
        margin-top: 15px;

        &:hover {
            background-color: #ebedf2;
            cursor: pointer;
        }

        .card-body {
            text-align: center;
        }
    }
}
