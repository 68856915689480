.account-container {
    h5 {
        cursor: pointer;
        margin-top: 30px;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        padding: 0.75rem;
    }

    .section {
        visibility: hidden;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        height: 0;
        padding: 0 1em;
    }

    .fa-arrow-up {
        display: none;
    }

    .open {
        .fa-arrow-down {
            display: none;
        }

        .fa-arrow-up {
            display: unset;
        }

        .section {
            visibility: unset;
            height: 85%;
            padding: 1em;

            .row {
                height: 100%;
            }
        }
    }
}
