.dropbox {
    border: 1px solid lightgrey;
    background: white;
    border-radius: 5px;
    color: dimgray;
    padding: 10px 10px;
    min-height: 150px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &.error-border {
        border: 1px solid $invalid !important;
    }

    &:hover {
        background: #efeded;

        .image-container {
            .media-item-remove {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
            }
        }
    }

    p {
        text-align: center;
    }

    .image-container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .media-item-remove {
            display: none;
            position: absolute;
            right: 10px;
            top: -10px;
            z-index: 100;

            button {
                margin-bottom: 3px;
            }
        }

        .single-image-container {
            height: 100px;
            position: relative;
            border: 1px solid #dee2e6 !important;
            border-radius: 0.25rem !important;
            margin: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                button {
                    display: block;
                }
            }

            button {
                position: absolute;
                bottom: -5px;
                right: -5px;
                font-size: 10px;
                padding: 3px 7px;
                display: none;
                z-index: 10;
            }

            .image {
                max-height: 100%;
                max-width: 100%;
                width: auto;
            }

            .button {
                display: flex;
                height: 100%;
                align-items: center;
                opacity: 0.7;

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    content: '';
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url("data:image/svg+xml,%3Csvg width='15' height='20' viewBox='0 0 38 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36.303 18.9783C36.819 19.2845 37.2476 19.7242 37.5455 20.2544C37.8434 20.7846 38 21.3868 38 21.999C38 22.6112 37.8434 23.2124 37.5455 23.7426C37.2476 24.2728 36.819 24.7126 36.303 25.0187L20.6977 34.2761L5.09249 43.5325C4.57649 43.8386 3.99129 43.9999 3.39547 44C2.79965 44.0001 2.21403 43.8395 1.69798 43.5335C1.18192 43.2275 0.753501 42.7875 0.455449 42.2574C0.157397 41.7273 0.000215906 41.126 0 40.5138V3.48817C4.34317e-05 2.8759 0.156987 2.27381 0.454969 1.74359C0.752951 1.21337 1.18141 0.773567 1.6975 0.467456C2.21358 0.161344 2.79908 -1.11568e-05 3.39499 5.78588e-10C3.9909 1.11579e-05 4.57642 0.161325 5.09249 0.467456L20.6977 9.72485L36.303 18.9783Z' fill='white'/%3E%3C/svg%3E");
                }
            }
        }
    }
}

.image-preview {
    margin: 0 auto;
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
    background-color: #efefef;

    > div {
        max-height: 100%;
        display: flex;

        video {
            outline: none;
        }
    }

    &.details-only {
        height: auto;
        background-color: white;
    }

    img {
        max-height: 100%;
        max-width: 100%;
    }

    .media-details {
        position: absolute;
        right: -100px;
        top: 0;
        bottom: 0;
        visibility: hidden;
        transition: all 0.5s ease-in-out;
        padding: 0 20px;
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: -15px 0px 15px -15px $grey;

        &.details-only {
            position: unset;
            visibility: unset;
            box-shadow: none;
            flex-direction: row;
            flex-wrap: wrap;
            width: auto;
            padding-bottom: 10px;
        }

        .details-item {
            padding-right: 10px;
        }
    }

    &:hover {
        img,
        video {
            opacity: 0.5;
        }

        .media-details {
            visibility: visible;
            background-color: white;
            right: 0;
        }
    }

    .video {
        max-width: 100%;
        max-height: 100%;
    }
}

.image-tile {
    height: 270px;

    &.small {
        height: 160px;
    }
}

.img-fluid,
.img-thumbnail {
    border: unset !important;
    border-radius: unset !important;
    max-height: 95% !important;
    background-color: #efefef !important;
}

.preview-image-container {
    border: 1px solid #dee2e6 !important;
    border-radius: 0.25rem !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer;
    background-color: #efefef;

    &:hover {
        .media-item-overlay {
            display: block;
        }

        .media-item-title {
            background: rgba(0, 0, 0, 0.7);
        }
    }

    .media-item-overlay {
        display: none;
    }

    .button {
        display: flex;
        height: 100%;
        align-items: center;
        opacity: 0.7;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            content: '';
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg width='30' height='40' viewBox='0 0 38 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36.303 18.9783C36.819 19.2845 37.2476 19.7242 37.5455 20.2544C37.8434 20.7846 38 21.3868 38 21.999C38 22.6112 37.8434 23.2124 37.5455 23.7426C37.2476 24.2728 36.819 24.7126 36.303 25.0187L20.6977 34.2761L5.09249 43.5325C4.57649 43.8386 3.99129 43.9999 3.39547 44C2.79965 44.0001 2.21403 43.8395 1.69798 43.5335C1.18192 43.2275 0.753501 42.7875 0.455449 42.2574C0.157397 41.7273 0.000215906 41.126 0 40.5138V3.48817C4.34317e-05 2.8759 0.156987 2.27381 0.454969 1.74359C0.752951 1.21337 1.18141 0.773567 1.6975 0.467456C2.21358 0.161344 2.79908 -1.11568e-05 3.39499 5.78588e-10C3.9909 1.11579e-05 4.57642 0.161325 5.09249 0.467456L20.6977 9.72485L36.303 18.9783Z' fill='white'/%3E%3C/svg%3E");
        }
    }
}

.media-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 100px;
    background-color: #efefef;
}

.preview-image-container {
    .media-container {
        min-width: unset;
    }
}

.media-preview-item-remove {
    position: absolute;
    top: -10px;
    right: 5px;
    z-index: 10;
}

.media-item-title {
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    max-width: 100%;
    font-size: 10px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    text-align: center;
    margin: 0 auto;
    padding: 5px;
    word-break: break-all;
    z-index: 10;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.multiple-checkbox {
    position: absolute;
    top: -5px;
    left: 9px;
    width: 15px;
    height: 15px;
    border: 1px solid rgba(60, 60, 60, 0.5);
    border-radius: 25%;
    background-color: white;
}

.selected {
    border: 2px solid $darkGrey !important;

    .multiple-checkbox {
        background-color: $blue;
    }
}

.modal-text {
    padding: 0 20px;
}

.order-modal {
    .image-container {
        display: grid;
        grid-template-columns: repeat(4, minmax(150px, 1fr));
        align-items: center;
        justify-items: center;
        margin: auto;

        @media (max-width: $screen-md) {
            grid-template-columns: repeat(3, minmax(150px, 1fr));
        }

        .media-container {
            height: unset;
            padding: 10px;
            margin: 10px;
        }

        img {
            max-width: 100%;
            width: 100%;
            max-height: 200px;
            object-fit: cover;
            padding: 10px;
            cursor: grab;
        }

        span {
            position: absolute;
            left: 0px;
            font-size: 20px;
            width: 25px;
            height: 25px;
            padding: 0 8px;
            background-color: white;
        }
    }

    .primary-image img {
        position: relative;
        padding: 0 10px;
        object-fit: cover;
    }

    .secondary-images {
        display: flex;
        flex-flow: row wrap;

        img {
            padding: 0 10px 10px;
            object-fit: cover;
        }
    }

    .primary-image span,
    .secondary-images span {
        position: absolute;
        left: 10px;
        font-size: 20px;
        width: 25px;
        height: 25px;
        padding: 0 8px;
        background-color: white;
    }

    .image-relative-container {
        position: relative;
    }
}
