.awesome-table {
    .table td {
        vertical-align: middle;
    }

    .sortable-label:hover {
        background-color: #f1f5f7;
    }

    .some-selected {
        height: 13px;
        width: 13px;
        margin: auto;
        background-color: $blue;
        color: white;
        position: relative;
        border-radius: 2px;
        cursor: default;
        border: 1px solid $blue;
        margin-bottom: 6px;

        span {
            position: relative;
            top: -8px;
            left: 0px;
        }

        &:hover {
            background-color: $blueHover;
        }
    }

    .awesome-table-loader {
        position: absolute;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        width: 100%;

        .awesome-spinner {
            width: 40px;
            height: 40px;
            position: relative;
            text-align: center;

            -webkit-animation: sk-rotate 2s infinite linear;
            animation: sk-rotate 2s infinite linear;
        }

        .dot1,
        .dot2 {
            width: 60%;
            height: 60%;
            display: inline-block;
            position: absolute;
            top: 0;
            background-color: #4a9eda;
            border-radius: 100%;

            -webkit-animation: sk-bounce 2s infinite ease-in-out;
            animation: sk-bounce 2s infinite ease-in-out;
        }

        .dot2 {
            top: auto;
            bottom: 0;
            -webkit-animation-delay: -1s;
            animation-delay: -1s;
        }

        @-webkit-keyframes sk-rotate {
            100% {
                -webkit-transform: rotate(360deg);
            }
        }
        @keyframes sk-rotate {
            100% {
                transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
            }
        }

        @-webkit-keyframes sk-bounce {
            0%,
            100% {
                -webkit-transform: scale(0);
            }
            50% {
                -webkit-transform: scale(1);
            }
        }

        @keyframes sk-bounce {
            0%,
            100% {
                transform: scale(0);
                -webkit-transform: scale(0);
            }
            50% {
                transform: scale(1);
                -webkit-transform: scale(1);
            }
        }
    }

    .clickable-row {
        cursor: pointer;

        &:hover {
            background-color: #ebf3f7;
        }
    }

    .rows-per-page-label {
        text-align: end;
        font-size: 13px;
    }
    .per-page-awesome-table {
        .custom-select {
            min-width: 80px;
        }
    }

    .pagination {
        .page-item {
            cursor: pointer;

            &.disabled {
                cursor: default;
            }
        }

        margin-bottom: 0;
    }

    .filter {
        font-size: 12px;
        vertical-align: unset;

        .form-control {
            font-size: unset;
            height: calc(1.5em + 0.2rem + 2px);
            padding: 0.375rem 0.2rem;
        }

        .filter-box {
            position: relative;
        }

        i {
            cursor: pointer;

            &.fa-ban {
                cursor: default;
            }

            &.fa-filter {
                .options {
                    display: none;
                    position: absolute;
                    background-color: white;
                    min-width: 200px;
                    z-index: 200;
                    font-family: Arial;
                    font-weight: 400;
                }

                &:hover {
                    .options {
                        display: block;
                    }
                }
            }
        }

        & > div {
            font-family: Arial;
            font-weight: 400;
            display: flex;
            align-items: center;

            & > input,
            & > .v-select,
            & > .custom-switch {
                flex: 1;
                margin: 0 10px;
            }
        }
    }

    .applied-filters {
        .show-applied-filters-button {
            background-color: $purple;
            color: white;
        }

        .applied-filters-list {
            position: absolute;
            right: 15px;
            display: none;
            background-color: #d8eaf7;
            z-index: 200;

            .applied-filter {
                display: block;

                span {
                    font-weight: 700;
                }

                &:nth-child(3n) {
                    span {
                        color: $red;
                    }
                }

                &:nth-child(3n + 1) {
                    span {
                        color: $orange;
                    }
                }

                &:nth-child(3n + 2) {
                    span {
                        color: $green;
                    }
                }
            }
        }

        &:hover {
            .applied-filters-list {
                display: block;
            }
        }
    }

    .columns-selector {
        position: relative;

        @media (max-width: $screen-sm) {
            position: static;
        }

        .show-columns-selector-button {
            background-color: $blueHover;
            color: white;
        }

        .labels-list {
            position: absolute;
            right: 5px;
            display: none;
            background-color: #d8eaf7;
            z-index: 200;
            width: 300px;
        }

        &:hover {
            .labels-list {
                display: block;
            }
        }
    }

    .sum-section {
        td {
            border-top: 1px solid black;
            background-color: #eee;
            font-weight: 600;
        }
    }

    a {
        color: $grey;

        &:hover {
            color: $blue;
        }
    }

    .sorting-on {
        color: $lightGreen;
    }

    .sorting-off {
        color: $lightRed;
    }

    .alert-info {
        color: $darkGrey;
    }

    input[type='checkbox'] {
        width: 13px !important;
        height: 13px !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        box-shadow: none;
        border-radius: 2px;
        cursor: default;
        border: 1px solid $blue;
        outline-width: 0px;
        position: relative;

        &:checked {
            background-color: $blue;

            &:after {
                content: '\2713';
                color: white;
                position: absolute;
                top: -1px;
                left: -0.5px;
                line-height: 0.85rem;
                font-size: 0.85rem;
            }

            &:hover {
                &:after {
                    background-color: $blueHover;
                }
            }
        }
    }

    .scroll-bar {
        width: 100%;
        height: 10px;
        display: block;
        overflow-x: auto;
        overflow-y: hidden;

        .inner {
            height: 1px;
        }
    }

    .blocked-row {
        background-color: #a4060752 !important;
    }

    .pending-row {
        background-color: #e9a300fd !important;
    }

    .arrived-row {
        background-color: #9ec2a3fd !important;
    }

    .done-row {
        background-color: #fdbafffd !important;
    }
}
