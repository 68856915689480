.form-control {
    &:focus {
        box-shadow: unset !important;
        border-color: $baseColor !important;
    }
}

.alert-dismissible {
    .close {
        bottom: 0;
    }

    &.were-changes-alert {
        position: fixed;
        top: $navbarHeight !important;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        z-index: 10;
    }
}

.action-button {
    color: $grey;
    border: none;
    outline: none;
    background: none;

    &:hover {
        color: $blue;
    }
}

@-webkit-keyframes spinner {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner {
    display: inline-block;
    width: 18px;
    height: 18px;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 1.25s linear infinite;
    animation: spinner-border 1.25s linear infinite;
}

.import-csv-modal {
    .errors-box {
        color: $lightRed;
        max-height: 150px;
        overflow-y: auto;
        text-align: center;
    }

    .response-box {
        text-align: center;

        .success {
            color: $green;
        }

        .failed {
            color: $lightRed;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.error-box {
    color: $invalid;
}

.error-border {
    border: 1px solid $invalid !important;
}

.modal-title {
    word-break: break-word;
}

.vs--single {
    height: 38px;

    .vs__dropdown-toggle {
        height: 38px;
    }
}
