html {
    height: calc(100% - #{$navbarHeight});
}

body,
#app,
.wrapper {
    height: 100%;
    padding-right: 0px !important; // bvmodal adds padding-right to body
}

.modal-open {
    overflow: auto !important;
    padding-right: 0px !important;
}

.nav-bar {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    background-color: $darkGrey;
    height: $navbarHeight;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);

    .nav-text {
        font-size: 26px;

        @media (max-width: $screen-sm) {
            font-size: 23px;
        }

        a {
            text-decoration: none;
            color: white;
        }
    }

    .logo {
        height: 40px;
        margin-left: 50px;

        img {
            height: 100%;
        }
    }

    .menu {
        height: 60px;
        width: 90px;

        .dropdown,
        .btn,
        .nav-user {
            height: 60px;
            padding: 0;
        }

        .btn {
            display: flex;
            align-items: center;

            .nav-user {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: 48px;

                @media (max-width: $screen-sm) {
                    height: 50px;
                }

                img {
                    height: 80%;
                    object-fit: cover;
                    overflow: hidden;
                }
            }
        }

        .dropdown {
            button {
                border-color: $darkGrey !important;
                background-color: $darkGrey !important;
            }
        }
    }
}

.side-bar {
    background-color: white;
    position: fixed;
    width: $sidebarWidth;
    top: $navbarHeight;
    bottom: 30px;

    .menu-title {
        padding: 10px 20px;
        letter-spacing: 0.05em;
        pointer-events: none;
        cursor: default;
        font-size: 0.6875rem;
        text-transform: uppercase;
        color: #6e768e;
        font-weight: 600;

        @media (max-width: $screen-sm) {
            height: 0px;
            overflow: hidden;
            color: transparent;

            &::before {
                content: '';
                display: flex;
                height: 100%;
                border-top: 1px solid #6e768e;
            }
        }
    }

    .ps {
        height: 100%;
    }

    @media (max-width: $screen-sm) {
        width: $sidebarWidthMobile;

        li {
            a {
                justify-content: center;

                i {
                    margin-right: unset;
                }

                span {
                    display: none;
                }
            }
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 30px 0;

        li {
            padding: 9px 20px;

            @media (max-width: $screen-sm) {
                padding: 17px 20px;
            }

            a {
                display: flex;
                align-items: center;
                width: 100%;
                text-decoration: none;
                color: $grey;

                &:hover {
                    color: $blue;
                }

                i {
                    margin-right: 5px;
                    font-size: 19px;
                    width: 25px;
                    text-align: center;
                }

                span {
                    font-size: 14px;
                }

                &.router-link-exact-active,
                &.active {
                    color: $blue;
                }
            }
        }
    }
}

.content-page {
    display: flex;
    min-height: 100%;
    margin-top: $navbarHeight;
    margin-left: $sidebarWidth;
    padding-top: 20px;
    background-color: #e9ecef;

    @media (max-width: $screen-sm) {
        margin-left: $sidebarWidthMobile;
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .page-title-box {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
        }

        .breadcrumb {
            @media (max-width: $screen-sm) {
                display: none;
            }

            .breadcrumb-item {
                font-size: 14px;

                a {
                    text-decoration: none;
                    color: $grey;
                }

                &.active {
                    color: #adb5bd;
                    cursor: default;
                }

                & + .breadcrumb-item {
                    &:before {
                        content: '>';
                        font-size: 10px;
                        display: flex;
                        align-items: center;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.footer {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    text-align: center;
    color: $grey;
}

.footer-main {
    margin-top: 20px;
    background-color: $darkGrey;
    color: white;
    margin-left: -#{$sidebarWidth};
    z-index: 100;

    @media (max-width: $screen-sm) {
        margin-left: -#{$sidebarWidthMobile};
    }
}

.auth-background {
    background: #588fe6;
    background: -webkit-linear-gradient(to top, #fff, #588fe6);
    background: linear-gradient(to top, #fff, #588fe6);
    height: calc(100% + #{$navbarHeight});
    width: 100%;

    .auth-layout {
        padding-top: 30px;
        padding-bottom: 30px;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        a {
            color: $baseColor;

            &.btn-info {
                color: white;
            }
        }
    }
}

.item-information {
    .section {
        background-color: #fafafa;
        margin: 15px 0;

        &:nth-child(2n) {
            background-color: #eaeaea;

            h5 {
                &.bg-light {
                    background-color: #eaeaea !important;
                }
            }
        }
    }
}

.loader-layout {
    background: #588fe6;
    background: -webkit-linear-gradient(to top, #fff, #588fe6);
    background: linear-gradient(to top, #fff, #588fe6);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        transform: rotate(45deg);
        transform-origin: 40px 40px;

        div {
            top: 32px;
            left: 32px;
            position: absolute;
            width: 32px;
            height: 32px;
            background: $baseColor;
            animation: loader 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);

            &:after,
            &:before {
                content: ' ';
                position: absolute;
                display: block;
                width: 32px;
                height: 32px;
                background: $baseColor;
            }

            &:before {
                left: -24px;
                border-radius: 50% 0 0 50%;
            }

            &:after {
                top: -24px;
                border-radius: 50% 50% 0 0;
            }
        }
    }

    @keyframes loader {
        0% {
            transform: scale(0.95);
        }
        5% {
            transform: scale(1.1);
        }
        39% {
            transform: scale(0.85);
        }
        45% {
            transform: scale(1);
        }
        60% {
            transform: scale(0.95);
        }
        100% {
            transform: scale(0.9);
        }
    }
}

.error-layout {
    .message {
        text-align: center;
        padding: 35px;
    }

    .buttons-box {
        display: flex;
        justify-content: space-between;
    }
}
