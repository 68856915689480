$screen-sm: 768px !default;
$screen-md: 991px !default;

$navbarHeight: 70px;
$sidebarWidth: 240px;
$sidebarWidthMobile: 70px;

$baseColor: #326cc8;

$lightGrey: #efefef;
$grey: #6c757d;
$darkGrey: #33414f;
$blue: #17a2b8;
$blueHover: #128fa3;
$lightBlue: #f1f5f7;
$lightGreen: #7ce68a;
$green: #52aa5e;
$yellow: #f7cb15;
$purple: #5b1865;
$lightRed: #e67373;
$red: #a40606;
$orange: #ff9f1c;
$invalid: #dc3545;
