.btn-primary {
    background-color: $baseColor !important;
    border-color: $baseColor !important;
    color: white !important;
}

.btn-secondary {
    background-color: $grey !important;
    border-color: $grey !important;
}

.btn-info {
    background-color: $darkGrey !important;
    border-color: $darkGrey !important;
}

.btn-success {
    background-color: $green !important;
    border-color: $green !important;
}

.btn-danger {
    background-color: $red !important;
    border-color: $red !important;
}

.btn-warning {
    background-color: $orange !important;
    border-color: $orange !important;
    color: white !important;
}

.btn-static {
    width: 112px;

    @media only screen and (min-width: 1200px) and (max-width: 1406px) {
        width: 100%;
    }
}
